import React, {Component} from 'react'
import {Card, Col, Row, Icon, Table, List, Radio, DatePicker, Spin} from 'antd'
import styles from './style.less'
import moment from 'moment'
import _ from 'lodash'
import {
    RadialBarChart,
    RadialBar,
    Legend,
    Tooltip,
    CartesianGrid,
    XAxis,
    YAxis,
    ComposedChart,
    Line,
    Area,
    BarChart,
    Bar
} from 'recharts'

import Request from '../../request'

/*const data = [
  {
    'name': 'unknow',
    'uv': 6.67,
    'pv': 4800,
    'fill': '#ffc658'
  }
]*/

class Dashboard extends Component {


    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {

        return (
            <div>
                <h1>This is Dashboard here</h1>
                <p>Did you get here via Redux?</p>
            </div>
        )

    }

}

export default Dashboard

