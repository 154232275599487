import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import {
    Card,
    Form,
    Tooltip,
    Button,
    Spin,
    Row,
    Col,
    AutoComplete,
    Select,
    notification
} from 'antd'
import Request from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from "moment/moment";

const {Option} = Select

@Form.create()
class AllHerds extends Component {

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }


        return new Promise(async (resolve) => {
            if (!params.sortOrder) {
                params.sortField = 'createdAt'
                params.sortOrder = 'desc'
            }
            this.setState({filters: {...params}})

            let data = await Request.herdList({
                ...params, regExFilters: ['family_name', 'first_name', 'village_ward']
            })
            this.setState({count: data.total})
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
        }


    }

    async componentDidMount() {


    }

    exportData = async () => {
        console.log(this.state.filters)
        this.setState({startLoading: true})

        let data = await Request.herdList({
            ...this.state.filters,
            export: true
        })
        this.setState({startLoading: false})

        if (!data.error) {
            window.open(data.url)

        } else {
            return notification.error({message: 'Error in Export File'})
        }
    }


    render() {
        const {dispatch} = this.props

        let {userList, count} = this.state
        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                searchTextName: 'id'

            },
            {
                title: 'First_Name',
                dataIndex: 'first_name',
                key: 'first_name',
                searchTextName: 'first_name'
            },

            {
                title: 'Family_name',
                dataIndex: 'family_name',
                key: 'family_name',


            },
            {
                title: 'Village_ward',
                dataIndex: 'village_ward',
                key: 'village_ward',
                searchTextName: 'village_ward',


            },
            {
                title: 'Date Entry',
                dataIndex: 'date_entry',
                key: 'date_entry',
                sorter: true,
                searchDateName: 'date_entry',
                render: (val, record) => {
                    return <div>
                        <div>{record && record.date_entry ? moment(record.date_entry).format('lll') : ''}</div>
                    </div>
                }
                // width:100

            },
            {
                title: 'VDC',
                key: 'vdc',
                dataIndex: 'vdc',
                searchTextName: 'vdc'

            },
            {
                title: 'District',
                key: 'district',
                dataIndex: 'district',
                searchTextName: 'district'

            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                searchDateName: 'createdAt',
                render: (val, record) => {
                    return <div>
                        <div>{moment(record.createdAt).format('lll')}</div>
                    </div>
                }
                // width:100

            },

            // {
            //     key: 'actions',
            //     title: 'Action',
            //     width: 100,
            //     fixed: 'right',
            //     render: (text, record) => {
            //         return <React.Fragment>
            //
            //         </React.Fragment>
            //     }
            // },


        ]
        return (
            <PageHeaderWrapper
                title={`All Herds (${count || 0})`}>

                <Card bordered={true}>
                    <Row>
                        <Col>
                            <div style={{float: 'right'}}>
                                <Button
                                    style={{marginBottom: 10, marginLeft: 10}}
                                    type="default"
                                    onClick={() => {
                                        this.exportData()
                                    }}
                                    loading={this.state.startLoading}>
                                    Export Excel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllHerds)
