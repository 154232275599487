import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard'
import UserList from './containers/user/all'
import AddUser from './containers/user/add'
import AnimalList from './containers/animal/all'
import AddAnimal from './containers/animal/add'
import CollectionList from './containers/collection/all'
import AddCollection from './containers/collection/add'
import CollectionPoint from './containers/collectionPoint'
import HerdList from './containers/herd/all'
import CollectionPointList from './containers/collectionPoint/all'

const menu = [
    {
        'path': '/dashboard',
        'name': 'Dashboard',
        'icon': 'dashboard',
        'key': 'dashboard',
        'homepage': true,
        'component': Dashboard,
        'authority': [
            'admin'
        ],

    },
    {
        'path': '/collectionPoint',
        'name': 'Collection Point',
        'icon': 'setting',
        'key': 'collectionPoint',
        'homepage': true,
        'authority': [
            'admin'
        ],
        'children': [
            {
                'path': '/collectionPoint/add',
                'name': 'Add Collection Point',
                'key': 'addCollectionPoint',
                'title': 'Add Collection Point',
                // dontShowOnMenu: true,
                'component': CollectionPoint,
                'authority': [
                    'admin'
                ]
            },
            {
                'path': '/collectionPoint/all',
                'name': 'Collection Point List',
                'title': 'Collection Point List',
                'component': CollectionPointList,
                'authority': [
                    'admin'
                ]
            },
        ]

    },

    // {
    //     'path': '/herd',
    //     'name': 'Herd',
    //     'icon': 'Herd',
    //     'key': 'user',
    //     'authority': [
    //         'admin'
    //     ],
    //     'children': [
    //         {
    //             'path': '/user/add',
    //             'name': 'Add Herd',
    //             //'icon': 'user',
    //             'key': 'addUser',
    //             'title': 'Add Herd',
    //             // dontShowOnMenu: true,
    //             'component': AddUser,
    //             'authority': [
    //                 'admin'
    //             ]
    //         },
    //         {
    //             'path': '/user/all',
    //             'name': 'Herd List ',
    //             'title': 'Herd List',
    //             'component': UserList
    //         },
    //         {
    //             'path': '/user/edit',
    //             'name': 'Update Herd',
    //             'title': 'Update Herd',
    //             'key': 'editUser',
    //             dontShowOnMenu: true,
    //             'component': AddUser
    //         },
    //     ]
    // },
    {
        'path': '/herd',
        'name': 'Herd',
        'icon': 'Herd',
        'key': 'user',
        'authority': [
            'admin'
        ],
        'component': HerdList
        // 'children': [
        //     {
        //         'path': '/herd/all',
        //         'name': 'Herd List ',
        //         'title': 'Herd List',
        //         'component': HerdList
        //     },
        // ]
    },
    {
        'path': '/animal',
        'name': 'Animal',
        'icon': 'animal',
        'key': 'animal',
        'authority': [
            'admin'
        ],
        'children': [
            {
                'path': '/animal/add',
                'name': 'Add Animal',
                //'icon': 'user',
                'key': 'addAnimal',
                'title': 'Add Animal',
                dontShowOnMenu: true,
                'component': AddAnimal,
                'authority': [
                    'admin'
                ]
            },
            {
                'path': '/animal/all',
                'name': 'Animal List',
                'title': 'Animal List',
                'component': AnimalList
            },
            {
                'path': '/animal/edit',
                'name': 'Update Animal',
                'title': 'Update Animal',
                'key': 'editAnimal',
                dontShowOnMenu: true,
                'component': AddAnimal
            },
        ]
    },
    {
        'path': '/collection',
        'name': 'Collection',
        'icon': 'collection',
        'key': 'collection',
        'authority': [
            'admin'
        ],
        'children': [
            {
                'path': '/collection/add',
                'name': 'Add Collection',
                //'icon': 'user',
                'key': 'addCollection',
                'title': 'Add Collection',
                dontShowOnMenu: true,
                'component': AddCollection,
                'authority': [
                    'admin'
                ]
            },
            {
                'path': '/collection/all',
                'name': 'Collection List',
                'title': 'Collection List',
                'component': CollectionList
            },
            {
                'path': '/collection/edit',
                'name': 'Update Collection',
                'title': 'Update Collection',
                'key': 'editCollection',
                dontShowOnMenu: true,
                'component': AddCollection
            },
        ]
    },


]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
    console.log("query is", query);
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {

    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = (keyString) => {

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    return val
}
//</editor-fold>

export default menu

