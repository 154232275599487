import {notification} from 'antd'
import axios from 'axios'

import {apiUrl} from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
})

let getToken = () => {
    return ({'headers': {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
}

function download(record) {
    fetch(`${apiUrl}/recordingStream?callingTime=${record.callingTime}&duration=${record.duration}&roNumber=${record.roNumber}`, getToken()).then(function (t) {
        let name = t.headers.get('Content-Disposition').split('=')[1].replace(/['"]+/g, '')
        return t.blob().then((b) => {
                var a = document.createElement('a')
                a.href = URL.createObjectURL(b)
                a.setAttribute('download', name)
                a.click()
            }
        )
    })
}

class Request {

    error = (err) => {
        try {
            if (err.response.status === 401) {
                console.log("clearing localstorage")
                localStorage.clear()
                window.location.reload()
            }
        } catch (e) {
        }
    }

    constructor() {

    }

    login(data) {
        return new Promise((next, error) => {
            authAxios.post('/login', data)
                .then((d) => {
                    console.log(d, '============')
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    userList(data) {
        return new Promise(next => {
            authAxios
                .get('/user', {params: {...data}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {


                    this.error(err)
                })
        })
    }

    addUser(data) {
        return new Promise(next => {
            authAxios
                .post('/user', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateUser(data) {
        return new Promise(next => {
            authAxios
                .put('/user/' + data._id, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    getUserById(data) {
        return new Promise(next => {
            authAxios
                .get('/user/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addAnimal(data) {
        return new Promise(next => {
            authAxios
                .post('/api/animal', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateAnimal(data) {
        return new Promise(next => {
            authAxios
                .put('/api/animal/' + data._id, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    animalList(data) {
        return new Promise(next => {
            authAxios
                .get('/api/animal', {params: {...data}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {


                    this.error(err)
                })
        })
    }

    getAnimalById(data) {
        return new Promise(next => {
            authAxios
                .get('/api/animal/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addCollection(data) {
        return new Promise(next => {
            authAxios
                .post('/api/collection', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateCollection(data) {
        return new Promise(next => {
            authAxios
                .put('/api/collection/' + data._id, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    collectionList(data) {
        return new Promise(next => {
            authAxios
                .get('/api/collection', {params: {...data}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {


                    this.error(err)
                })
        })
    }

    getCollectionById(data) {
        return new Promise(next => {
            authAxios
                .get('/api/collection/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteCollection(data) {
        return new Promise(next => {
            authAxios
                .delete(`/api/collection/` + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    herdSearch(data) {
        return new Promise(next => {
            authAxios
                .post('/herdSearch', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addCollectionPoint(data) {
        return new Promise(next => {
            authAxios
                .post('/user/setting', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }
    herdList(data) {
        return new Promise(next => {
            authAxios
                .get('/api/herd', {params: {...data}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {


                    this.error(err)
                })
        })
    }

}


export default new Request()
