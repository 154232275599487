import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import {
    Card,
    Form,
    Tooltip,
    Button,
    Switch
} from 'antd'
import Request from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {connect} from 'react-redux'
import moment from "moment/moment";

@Form.create()
class AllUsers extends Component {

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }


        return new Promise(async (resolve) => {
            if (!params.sortOrder) {
                params.sortField = 'createdAt'
                params.sortOrder = 'desc'
            }
            params.userType = {$nin: 'admin'};
            let data = await Request.userList({
                ...params, regExFilters: ['mobile', 'name', 'email']
            })
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
        }

    }

    componentDidMount() {
    }

    render() {
        const {dispatch} = this.props

        let {} = this.state
        const columns = [
            {
                title: 'Id',
                dataIndex: 'userId',
                key: 'userId',
                searchTextName: 'userId'

            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email'
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',

            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'active',
                render: ((val) => {

                    return <Switch checked={val}/>

                })

            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                searchDateName: 'createdAt',
                render: (val, record) => {
                    return <div>
                        <div>{moment(record.createdAt).format('lll')}</div>
                    </div>
                }

            },

            {
                key: 'actions',
                title: 'Action',
                width: 100,
                fixed: 'right',
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit user">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(getUrlPushWrapper('user.editUser', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                    </React.Fragment>
                }
            },

        ]
        return (
            <PageHeaderWrapper
                title={'All Herds'}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
