import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,
    Spin
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../request'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../modules/actions'
import Request from '../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'


@Form.create()
class AddSetting extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {countryList} = this.state
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                dispatch(showLoader())

                let x = null

                x = await Request.addCollectionPoint(values)


                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()

                    }

                    if (this.state.id) {
                        dispatch(goBack())

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.state = {loader: true}

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);
        this.setState({loader: false})


    }

    render() {
        let {loader} = this.state

        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;


        const inputTypes = {
            fields: [

                {
                    label: 'Name',
                    key: 'name',
                    required: true
                }


            ]
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <PageHeaderWrapper
                title={'Add Collection Point'}
            >

                <Spin spinning={loader}>
                    <Card bordered={true}>
                        <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                            <GetAllFormFields apiurl={API_URL}
                                              inputSchema={inputTypes} formItemLayout={formItemLayout}
                                              getFieldDecorator={getFieldDecorator}/>


                            <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    SAVE
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </Spin>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSetting)
