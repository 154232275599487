import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import {
    Card,
    Form,
    Tooltip,
    Button,
    Spin,
    Row,
    Col,
    AutoComplete,
    Select
} from 'antd'
import Request from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from "moment/moment";
import {notification} from "antd/lib/index";

const {Option} = Select

@Form.create()
class AllAnimals extends Component {

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }


        return new Promise(async (resolve) => {
            if (!params.sortOrder) {
                params.sortField = 'createdAt'
                params.sortOrder = 'desc'
            }
            if (this.state.herdNameId) {
                params.id_herd = this.state.herdNameId
            }
            this.setState({filters: {...params}})

            let data = await Request.animalList({
                ...params, regExFilters: ['name']
            })
            this.setState({count: data.total})

            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
        }
        this.searchUser = _.debounce(this.handleUserSearch, 100, true)


    }

    async componentDidMount() {

        let {data: allHerds} = await Request.herdList({});
        this.setState({herdList: allHerds})

    }

    onUserSelect = (val) => {
        let selectedUser = this.state.herdList.find((obj) => obj._id === val)
        if (selectedUser) {
            this.setState({
                herdNameId: selectedUser.id,
                selectedUser: `${selectedUser.first_name} (${selectedUser.id})`
            }, () => {
                this.table.current && this.table.current.reload()
            })
        }
    }
    handleUserSearch = async (test) => {
        console.log(test)
        this.setState({selectedUser: test, showSpin: true})
        if (test == '') {
            console.log("empty");
            this.setState({
                herdList: this.state.herdList,
                selectedUser: test,
                herdNameId: null,
                showSpin: false

            }, () => this.table.current.reload())
            return false
        }
        let {data, error, message} = await Request.herdSearch({text: test})
        if (!error) {
            this.setState({herdList: data}, () => {
                this.setState({showSpin: false})
            })
        }
    }
    exportData = async () => {
        console.log(this.state.filters)

        this.setState({startLoading: true})
        let data = await Request.animalList({
            ...this.state.filters,
            export: true
        })
        this.setState({startLoading: false})
        if (!data.error) {
            window.open(data.url)

        } else {
            return notification.error({message: 'Error in Export File'})
        }


    }


    render() {
        const {dispatch} = this.props

        let {herdList, count} = this.state
        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                searchTextName: 'id'

            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name'
            },
            {
                title: 'Herd Id',
                key: 'id_herd',
                dataIndex: 'id_herd',
                searchTextName: 'id_herd'

            },


            {
                title: 'Sex',
                dataIndex: 'sex',
                key: 'sex',
                filters: [
                    {
                        text: 'Female',
                        value: 'female',
                    },
                    {
                        text: 'Male',
                        value: 'male',
                    }
                ],
                render: (val) => {
                    return <div>{_.startCase(val)}</div>
                }

            },
            {
                title: 'Date of Birth',
                dataIndex: 'date_birth',
                key: 'date_birth',
                sorter: true,
                searchDateName: 'date_birth',
                render: (val, record) => {
                    return <div>
                        <div>{record && record.date_birth ? moment(record.date_birth).format('l') : ''}</div>
                    </div>
                }
                // width:100

            },
            {
                title: 'Date Entry',
                dataIndex: 'date_entry',
                key: 'date_entry',
                sorter: true,
                searchDateName: 'date_entry',
                render: (val, record) => {
                    return <div>
                        <div>{record && record.date_entry ? moment(record.date_entry).format('lll') : ''}</div>
                    </div>
                }
                // width:100

            },
            {
                title: 'Id_sire',
                key: 'id_sire',
                dataIndex: 'id_sire',
                searchTextName: 'id_sire'

            },
            {
                title: 'Id_dam',
                key: 'id_dam',
                dataIndex: 'id_dam',
                searchTextName: 'id_dam'

            },
            {
                title: 'Breed_sire',
                key: 'breed_sire',
                dataIndex: 'breed_sire',
                searchTextName: 'breed_sire'

            },
            {
                title: 'Breed_dam',
                key: 'breed_dam',
                dataIndex: 'breed_dam',
                searchTextName: 'breed_dam'

            },

            // {
            //     key: 'actions',
            //     title: 'Action',
            //     width: 100,
            //     fixed: 'right',
            //     render: (text, record) => {
            //         return <React.Fragment>
            //
            //         </React.Fragment>
            //     }
            // },


        ]
        return (
            <PageHeaderWrapper
                title={`All Animals (${count || 0})`}>

                <Card bordered={true}>
                    <Row>

                        <Col span={8}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Herd Name</h4>
                            <AutoComplete
                                value={this.state.selectedUser}
                                notFoundContent={this.state.showSpin && <Spin/>}
                                autoComplete={'none'}
                                onSelect={this.onUserSelect}
                                onSearch={this.searchUser}
                                placeholder={'Search Herd'}
                            >{herdList && herdList.map((val, item) => (
                                <Option value={val._id}>
                                    <>
                                        <div>{`${val.first_name} (${val.id})`}</div>
                                    </>
                                </Option>
                            ))}

                            </AutoComplete>
                        </Col>

                        <Col>
                            <div style={{float: 'right'}}>
                                <Button
                                    style={{marginBottom: 10, marginLeft: 10}}
                                    type="default"
                                    onClick={() => {
                                        this.exportData()
                                    }}
                                    loading={this.state.startLoading}>
                                    Export Excel
                                </Button>
                            </div>
                        </Col>


                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllAnimals)
