import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import styles from '../../../containers/dashboard/style.less'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import {
    Card,
    Form,
    Tooltip,
    Button,
    Tag,
    Select,
    Row, Col,
    Popconfirm,
    notification
} from 'antd'
import Request from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
//import 'moment/locale/ne'

const {Option} = Select

@Form.create()
class AllCollections extends Component {

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }


        return new Promise(async (resolve) => {
            if (!params.sortOrder) {
                params.sortField = 'createdAt'
                params.sortOrder = 'desc'
            }
            if (this.state.animalId) {
                params.animalId = this.state.animalId
            }
            if (this.state.herdId) {
                params.id_herd = this.state.herdId
            }
            this.setState({filters: {...params}})
            let data = await Request.collectionList({
                ...params, regExFilters: ['name']
            })
            this.setState({count: data.total})

            console.log(data)
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            animalType: []
        }

    }

    deleteCollection = async data => {
        this.setState({loading: true})
        let x = await Request.deleteCollection(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            return notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            return notification.error({
                message: x.message || 'Try Again',
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    exportData = async () => {
        console.log(this.state.filters)

        this.setState({startLoading: true})
        let data = await Request.collectionList({
            ...this.state.filters,
            export: true
        })
        this.setState({startLoading: false})
        if (!data.error) {
            window.open(data.url)

        } else {
            return notification.error({message: 'Error in Export File'})
        }


    }

    async componentDidMount() {
        let {data: herdList} = await Request.herdList({results: 1000})
        this.setState({herdList: herdList})
    }

    render() {
        const {dispatch} = this.props

        let {animalType, herdList, count} = this.state

        const columns = [
            {
                title: 'Herd Id',
                key: 'id_herd',
                dataIndex: 'id_herd'
            },
            // {
            //     title: 'Herd Name',
            //     key: 'herdName',
            //     render: (val, record) => {
            //         return <div>{record && record.herdId && record.herdId.name}</div>
            //     }
            // },
            {
                title: 'Animal Id',
                key: 'animalId',
                dataIndex: 'animalId'
                // render: (val, record) => {
                //     return <div>{record && record.animalId && record.animalId.animalId}</div>
                // }
            },

            {
                title: 'Status',
                key: 'status',
                filters: [
                    {
                        text: 'Accepted',
                        value: 'Accepted'
                    },
                    {
                        text: 'Rejected',
                        value: 'Rejected'
                    }
                ],
                render: (val, record) => {
                    return (
                        record && record.status ? <Tag
                            color={record.status === 'Accepted' ? 'green' : 'red'}>{record.status.toUpperCase()}</Tag> : ''
                    )
                }
            },
            {
                title: 'Weight',
                key: 'weight',
                sorter: true,
                render: (val, record) => {
                    return (<p>{record.weight}</p>)
                }
            },
            {
                title: 'Fat',
                key: 'collectionParams.fat',
                sorter: true,
                render: (val, record) => {
                    console.log(val, ' val')
                    return (<p>{record.collectionParams.fat}</p>)
                }
            },
            {
                title: 'SNF',
                key: 'collectionParams.snf',
                sorter: true,
                render: (val, record) => {
                    console.log(val, ' val')
                    return (<p>{record.collectionParams.snf}</p>)
                }
            },

            {
                title: 'OTH',
                key: 'collectionParams',
                sorter: true,
                render: (val, record) => {
                    console.log(val, ' val')
                    return (<div className={styles.internal}>
                        <table>

                            <tr>
                                <td>Added Water</td>
                                <td><p>{record.collectionParams['added-water']}</p></td>
                            </tr>
                            <tr>
                                <td>Conductivity</td>
                                <td><p>{record.collectionParams['conductivity']}</p></td>
                            </tr>
                            <tr>
                                <td>Density</td>
                                <td><p>{record.collectionParams['density']}</p></td>
                            </tr>
                            <tr>
                                <td>Freezing Point</td>
                                <td><p>{record.collectionParams['freezing-point']}</p></td>
                            </tr>
                            <tr>
                                <td>Lactose</td>
                                <td><p>{record.collectionParams['lactose']}</p></td>
                            </tr>
                            <tr>
                                <td>Ph</td>
                                <td><p>{record.collectionParams['ph']}</p></td>
                            </tr>
                            <tr>
                                <td>Protein</td>
                                <td><p>{record.collectionParams['protein']}</p></td>
                            </tr>
                            <tr>
                                <td>Salts</td>
                                <td><p>{record.collectionParams['salts']}</p></td>
                            </tr>
                            <tr>
                                <td>Temp</td>
                                <td><p>{record.collectionParams['temp']}</p></td>
                            </tr>

                        </table>
                    </div>)
                }
            },

            {
                title: 'Collection Time',
                key: 'time',
                filters: [
                    {
                        text: 'Morning',
                        value: 'Morning'
                    },
                    {
                        text: 'Evening',
                        value: 'Evening'
                    }
                ]

            },
            {
                title: 'Sync',
                key: 'sync',
                dataIndex: 'sync',
                render: (val, record) => {
                    return (
                        <div>{val ? <Tag color={'green'}>True</Tag> : ''}</div>
                    )
                }

            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                sorter: true,
                searchDateName: 'date',
                render: (val, record) => {
                    return <div>
                        <div>{moment(record.date).format('lll')}</div>
                    </div>
                }
                // width:100

            }

            // {
            //     key: 'actions',
            //     title: 'Action',
            //     width: 100,
            //     fixed: 'right',
            //     render: (text, record) => {
            //         return <React.Fragment>
            //
            //             <Tooltip title="Delete Collection">
            //                 <Popconfirm
            //                     title="Are you sure delete this Collection?"
            //                     onConfirm={() => {
            //                         this.deleteCollection({_id: record._id})
            //                     }}
            //                     onCancel={() => {
            //                         console.log()
            //                     }}
            //                     okText="Yes"
            //                     cancelText="No">
            //                     <Button
            //                         shape="circle"
            //                         size="small"
            //                         style={{marginRight: 6}}
            //                         icon="delete"
            //                     />
            //                 </Popconfirm>
            //
            //             </Tooltip>
            //
            //
            //             {/*<Tooltip title="Edit Animal">*/}
            //             {/*<Button*/}
            //             {/*shape="circle"*/}
            //             {/*style={{marginRight: 6}}*/}
            //             {/*size="small"*/}
            //             {/*onClick={() => {*/}
            //             {/*dispatch(getUrlPushWrapper('collection.editCollection', {*/}
            //             {/*id: record._id*/}
            //             {/*})*/}
            //             {/*)*/}
            //             {/*}}*/}
            //             {/*icon="edit"*/}
            //             {/*/>*/}
            //             {/*</Tooltip>*/}
            //         </React.Fragment>
            //     }
            // },

        ]

        return (
            <PageHeaderWrapper
                title={`All Collections (${count || 0})`}>

                <Card bordered={true}>
                    <Row>
                        <Col span={5}>
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Select Herd'}
                                optionFilterProp='children'
                                style={{width: 200}}
                                value={this.state.herdId}
                                onChange={async (id) => {
                                    if (id) {
                                        let {data: animalData} = await Request.animalList({id_herd: id})
                                        this.setState({animalType: animalData, animalId: ''})

                                        this.setState({herdId: id}, () => {
                                            (this.table.current) ? this.table.current.reload() : console.log('')

                                        })

                                    } else {
                                        this.setState({herdId: id, animalType: [], animalId: ''}, () => {
                                            (this.table.current) ? this.table.current.reload() : console.log('')

                                        })
                                    }

                                }}
                            >
                                {herdList && herdList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val.id}>{val.first_name}({val.id})</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                        <Col span={5}>
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Select Animal'}
                                optionFilterProp='children'
                                style={{width: 200}}
                                disabled={animalType && animalType.length ? false : true}
                                value={this.state.animalId}
                                onChange={id => {
                                    this.setState({animalId: id}, () => {
                                        (this.table.current) ? this.table.current.reload() : console.log('')

                                    })

                                }}
                            >
                                {animalType && animalType.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val.id}>{val.name}({val.id})</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                        <Col>
                            <div style={{float: 'right'}}>
                                <Button
                                    style={{marginBottom: 10, marginLeft: 10}}
                                    type="default"
                                    onClick={() => {
                                        this.exportData()
                                    }}
                                    loading={this.state.startLoading}>
                                    Export Excel
                                </Button>
                            </div>
                        </Col> </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCollections)
