import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,
    Spin
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'


@Form.create()
class AddUser extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {countryList} = this.state
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id

                    x = await Request.updateUser(values)
                } else {
                    x = await Request.addUser(values)
                }

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()

                    }

                    if (this.state.id) {
                        dispatch(goBack())

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.state = {loader: true}

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);

        let id = searchParams.get('id')
        if (id) {

            Request.getUserById({_id: id}).then(({data: userData, error, message}) => {

                console.log(userData, 'userData')
                if (!error) {

                    this.setState({
                        id: userData && userData._id,
                        loader: false
                    })

                    this.props.form.setFieldsValue({

                        name: userData && userData.name,
                        description: userData && userData.description,
                        email: userData && userData.email,
                        userId: userData && userData.userId
                    });
                } else {
                    this.setState({loader: false})
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            this.setState({loader: false})
        }

    }

    render() {
        let {loader} = this.state

        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;


        const inputTypes = {
            fields: [
                {
                    label: 'Id',
                    key: 'userId',
                    required: true,
                    type: 'number',
                    disabled: this.state.id ? true : false,


                },
                {
                    label: 'Name',
                    key: 'name',
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true,
                    type: 'email'
                },

                {
                    label: 'Password',
                    key: 'password',
                    type: 'password',
                    required: true,
                    hidden: this.state.id ? true : false
                },
                {
                    label: 'Description',
                    key: 'description',
                    type: 'textArea',
                    required: true
                },


            ]
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Herd' : 'Add Herd'}
            >

                <Spin spinning={loader}>
                    <Card bordered={true}>
                        <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                            <GetAllFormFields apiurl={API_URL}
                                              inputSchema={inputTypes} formItemLayout={formItemLayout}
                                              getFieldDecorator={getFieldDecorator}/>


                            <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    SAVE
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </Spin>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUser)
