import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Button,
  Card,
  InputNumber,
  Row, Col
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/ne'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { API_URL } from '../../../request'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'

@Form.create()
class AddCollection extends PureComponent {

  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { countryList } = this.state
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)
        dispatch(showLoader())

        let x = null
        if (this.state.id) {
          values._id = this.state.id

          x = await Request.updateCollection(values)
        } else {
          x = await Request.addCollection(values)
        }

        dispatch(hideLoader())

        if (!x.error) {

          notification.success({
            message: this.state.id
                     ? x.message
                     : x.message
          })

          if (!this.state.id) {
            this.props.form.resetFields()
            this.props.form.setFieldsValue({ date: moment() })

          }

          if (this.state.id) {
            dispatch(goBack())

          }

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }

  constructor (props) {
    super(props)
    this.state = {
      collectionParams: [
        'temp',
        'fat',
        'snf',
        'density',
        'protein',
        'lactose',
        'salts',
        'addedWater',
        'freezing-point',
        'cph',
        'mode',
        'sn',
        'conductivity'

      ]
    }

  }

  async componentDidMount () {
    let searchParams = new URLSearchParams(this.props.search)
    let { data: allUsers } = await Request.userList({ userType: 'herd' })
    this.setState({ userList: allUsers })

    let id = searchParams.get('id')
    if (id) {

      Request.getCollectionById({ _id: id }).then(({ data: collectionData, error, message }) => {

        console.log(collectionData, 'collectionData')
        if (!error) {

          this.setState({
            id: collectionData && collectionData._id
          })

          this.props.form.setFieldsValue({

            time: collectionData && collectionData.time,
            animalId: collectionData && collectionData.animalId && collectionData.animalId._id,
            date: collectionData && collectionData.date ? moment(collectionData.date) : ''
          })
        } else {
          return notification.error({
            message: 'Error Getting Data'
          })
        }
      })
    } else {
      this.props.form.setFieldsValue({ date: moment() })

    }
  }

  render () {
    let { animalType, userList, showAnimal, collectionParams } = this.state

    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const inputTypes = {
      fields: [
        {
          label: 'Select Herd',
          type: 'select',
          key: 'userId',
          showSearch: true,
          required: true,
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.name}(${x.userId})`,
          options: userList || [],
          onChange: async (userId) => {

            if (userId) {
              this.props.form.setFieldsValue({
                userId: userId,
                animalId: ''
              })

              let { data: animalType } = await Request.animalList({ userId: userId })
              this.setState({ animalType: animalType })

            } else {
              this.props.form.setFieldsValue({
                animalId: ''
              })
              this.setState({ animalType: [] })

            }

          }
        },
        {
          label: 'Animal Id',
          type: 'select',
          key: 'animalId',
          showSearch: true,
          disabled: animalType && animalType.length ? false : true,
          required: true,
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.name}(${x.animalId})`,
          options: animalType || [],
          onChange: animalId => {
            this.props.form.setFieldsValue({
              animalId: animalId
            })

          }
        },
        {
          label: 'Date',
          key: 'date',
          required: true,
          type: 'date'
        },
        {
          label: 'Time',
          key: 'time',
          required: true,
          type: 'select',
          options: ['Morning', 'Evening'],
          onChange: time => {
            this.props.form.setFieldsValue({
              time: time
            })

          }
        }

      ]
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    return (
      <PageHeaderWrapper
        title={this.state.id ? 'Update Collection' : 'Add Collection'}
      >

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{ marginTop: 8 }}>


            <GetAllFormFields apiurl={API_URL}
                              inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator} />


            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit' loading={this.props.loading}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCollection)
