import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import {
    Card,
    Form,
    Tag,
    Button,
    Spin,
    Row,
    Col,
    AutoComplete,
    Select
} from 'antd'
import Request from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from "moment/moment";
import {notification} from "antd/lib/index";

const {Option} = Select

@Form.create()
class AllCollectionPoint extends Component {

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }


        return new Promise(async (resolve) => {
            if (!params.sortOrder) {
                params.sortField = 'createdAt'
                params.sortOrder = 'desc'
            }

            params.userType = ['collectionPoint']
            this.setState({filters: {...params}})

            let data = await Request.userList({
                ...params, regExFilters: ['name', 'secretKey']
            })
            this.setState({count: data.total})
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
        }


    }

    exportData = async () => {
        console.log(this.state.filters)

        this.setState({startLoading: true})
        let data = await Request.userList({
            ...this.state.filters,
            export: true
        })
        this.setState({startLoading: false})
        if (!data.error) {
            window.open(data.url)

        } else {
            return notification.error({message: 'Error in Export File'})
        }


    }


    async componentDidMount() {


    }


    render() {
        const {dispatch} = this.props

        let {count} = this.state
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name',
                width: 100

            },
            {
                title: 'Key',
                dataIndex: 'secretKey',
                key: 'secretKey',
                width: 100,
                searchTextName: 'secretKey',


            },
            {
                title: 'Registered',
                dataIndex: 'isRegistered',
                key: 'isRegistered',
                width: 100,
                render: ((val) => {
                    return <Tag color={val ? 'green' : 'red'}>{val ? 'True' : 'False'}</Tag>
                }),
                filters: [
                    {
                        text: 'True',
                        value: 'true',
                    },
                    {
                        text: 'False',
                        value: 'false',
                    }
                ],


            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                searchDateName: 'createdAt',
                render: (val, record) => {
                    return <div>
                        <div>{moment(record.createdAt).format('lll')}</div>
                    </div>
                },
                width: 100

            }


        ]
        return (
            <PageHeaderWrapper
                title={`All Collection Points (${count ? count : 0})`}>

                <Card bordered={true}>
                    <Row>
                        <Col>
                            <div style={{float: 'right'}}>
                                <Button
                                    style={{marginBottom: 10, marginLeft: 10}}
                                    type="default"
                                    onClick={() => {
                                        this.exportData()
                                    }}
                                    loading={this.state.startLoading}>
                                    Export Excel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCollectionPoint)
