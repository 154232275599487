export default {}

export const pageTitle = 'Nepal Admin BackOffice'
export const subTitle = 'Powered by Scizers.com'


 // let mainURL = 'http://localhost:8040'
 let mainURL = 'https://nepal-r.amcu.in'
// //let mainURL = 'https://sales-r.ht-india.com'
if (process.env.NODE_ENV == 'production') {
  mainURL = 'https://nepal-r.amcu.in'
}

export const apiUrl = mainURL

